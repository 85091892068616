import React from "react";
import theme from "theme";
import { Theme, Text, Section, Icon, Strong, List, Box, Link, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaCoins, FaScroll, FaBalanceScale, FaRegHandshake } from "react-icons/fa";
import { MdAutorenew } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"corporate"} />
		<Helmet>
			<title>
				Bos Balance: Financial Expert for Expats & Freelancers in the Netherlands
			</title>
			<meta name={"description"} content={"Tailored financial and tax services for expats and freelancers in the Netherlands. Expert support in bookkeeping, VAT, and income tax by a qualified Chartered Accountant. Achieve financial clarity with Bos Balance."} />
			<meta property={"og:title"} content={"Bos Balance: Financial Expert for Expats & Freelancers in Netherlands"} />
			<meta property={"og:description"} content={"Tailored financial and tax services for expats and freelancers in the Netherlands. Expert support in bookkeeping, VAT, and income tax by a qualified Chartered Accountant. Achieve financial clarity with Bos Balance."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
		</Helmet>
		<Components.TopNavBar />
		<Section
			padding="88px 0 88px 0"
			background="linear-gradient(0deg,--color-light 0%,--color-secondary 32.9%,rgba(0,0,0,0) 81%,rgba(255, 255, 255, 1) 100%),url(https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/bicycles-against-sky-city.jpg?v=2024-09-13T09:28:27.217Z) center center/cover repeat scroll padding-box"
			quarkly-title="Hero-25"
			min-height="400px"
			align-items="end"
			justify-items="center"
			display="grid"
		>
			<Override
				slot="SectionContent"
				lg-justify-content="center"
				flex-direction="column"
				flex-wrap="wrap"
				grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="repeat(1, 1fr)"
				md-grid-template-columns="1fr"
				sm-margin="0px 0 0px 0"
				sm-width="100%"
				align-items="center"
				display="flex"
				justify-content="center"
				position="relative"
			/>
			<Text
				margin="0px auto 0px auto"
				font="normal 900 40px/1.2 --fontFamily-googleOpenSans"
				as="h1"
				color="#ffffff"
				text-align="center"
			>
				Bookkeeping for Corporates
				<br />
				in the Netherlands
			</Text>
		</Section>
		<Section background="--color-light" quarkly-title="Form">
			<Override slot="SectionContent" margin="0px auto 0px auto" sm-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="1fr 1fr"
				margin="50px 0px 0px 0px"
				md-grid-template-columns="1fr"
			>
				<Box
					lg-margin="0px 0px 40px 0px"
					display="grid"
					flex-direction="column"
					justify-content="flex-start"
					lg-width="100%"
					padding="36px 36px 36px 36px"
					margin="0px auto 0px auto"
					align-content="start"
					grid-gap="50px"
					md-padding="0 0 0 0"
				>
					<Text margin="0px 0px 0px 0px" font="--headline2" color="--primary" sm-text-align="center">
						Our Services
					</Text>
					<List
						margin="0px 0px 0px 0px"
						padding="0px 0px 0px 20px"
						list-style-type="none"
						as="ul"
						display="grid"
						grid-gap="16px"
						grid-template-columns="1fr 10fr"
						align-items="center"
					>
						<Icon
							category="fa"
							icon={FaCoins}
							size="64px"
							width="32px"
							color="--secondary"
						/>
						<Text margin="0px 0px 0px 0px" font="--base" color="--primary">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Comprehensive Financial Management
								<br />
							</Strong>
							We handle bookkeeping, VAT returns, annual financial statements, and corporate income tax filings to ensure your business stays compliant with Dutch and international regulations.
						</Text>
						<Icon
							category="fa"
							icon={FaScroll}
							size="64px"
							width="32px"
							color="--secondary"
						/>
						<Text margin="0px 0px 0px 0px" font="--base" color="--primary">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Payroll & Tax Administration
								<br />
							</Strong>
							Efficiently manage payroll and employee income tax returns while reviewing and submitting business-related tax forms and assessments.
						</Text>
						<Icon
							category="fa"
							icon={FaBalanceScale}
							size="64px"
							width="32px"
							color="--secondary"
						/>
						<Text margin="0px 0px 0px 0px" font="--base" color="--primary">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Financial Reporting & Audit Assistance
							</Strong>
							<br />
							Prepare detailed reports for shareholders, regulatory bodies, and assist with internal and external audits to meet compliance standards.{"\n"}
						</Text>
						<Icon
							category="md"
							icon={MdAutorenew}
							size="64px"
							width="32px"
							color="--secondary"
						/>
						<Text margin="0px 0px 0px 0px" font="--base" color="--primary">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Cash Flow & Automation Solutions
								<br />
							</Strong>
							Optimize your cash flow with forecasting tools and streamline financial processes using digital solutions like Exact Online, bank integrations, and automated scanning services.{"\n"}
						</Text>
						<Icon
							category="fa"
							icon={FaRegHandshake}
							size="64px"
							width="32px"
							color="--secondary"
						/>
						<Text margin="0px 0px 0px 0px" font="--base" color="--primary">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Expert Advisory
							</Strong>
							<br />
							Get tailored financial advice and support, helping you make informed decisions for business growth.
						</Text>
					</List>
				</Box>
				<Box
					lg-margin="20px 0px 0px 0px"
					padding="36px 36px 48px 36px"
					background="--color-darkL2"
					md-padding="28px 20px 28px 20px"
					lg-width="100%"
					margin="0px auto 0px auto"
					id="form"
					box-shadow="--xl"
					border-radius="15px"
				>
					<Components.CorporateForm reference="Corporate EN">
						<Override slot="button">
							Submit
						</Override>
					</Components.CorporateForm>
				</Box>
			</Box>
		</Section>
		<Section background="--color-light">
			<Box min-width="100px" min-height="100px">
				<Text margin="0px 0px 0px 0px" color="--primary" font="--lead" text-align="center">
					We provide expert financial and administrative support for all types of business structures in the Netherlands, including sole proprietorships, partnerships, and limited companies. No matter your business type, we ensure you meet all regulatory requirements and optimize your financial operations. To learn more about the different legal business structures, you can visit{" "}
					<Link href="https://business.gov.nl/starting-your-business/choosing-a-business-structure/business-structures-in-the-netherlands-overview/ " target="_blank" color="--secondary">
						this overview
					</Link>
					.{"\n"}
				</Text>
			</Box>
		</Section>
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			quarkly-title="AccountCanAssistWith"
			background="#fafaf9"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="80%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
			>
				<Text as="h2" font="--lead" color="--secondary" margin="0px 0px 8px 0px">
					HOW WE CAN HELP
				</Text>
				<Text color="--primary" font="--lead" margin="40px 0px 16px 0px">
					We begin by understanding your business needs, whether it's managing payroll, tax filings, or cash flow. Our team integrates seamlessly with your financial systems to streamline processes, handle regulatory submissions, and provide ongoing advice. Regular audits and compliance reviews ensure everything is in order.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				lg-justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
				background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/digital-audit.webp?v=2024-09-17T12:09:43.232Z) center/cover no-repeat scroll padding-box"
				border-radius="32PX"
				border-width="1px"
				border-style="solid"
				border-color="--color-skyBlue"
			/>
		</Section>
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-28"
			background="--color-light"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="80%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
			>
				<Text as="h2" font="--lead" color="--secondary" margin="0px 0px 8px 0px">
					WHY OUR CLIENTS CHOOSE US
				</Text>
				<Text color="--primary" font="--lead" margin="40px 0px 16px 0px">
					Our clients choose us for our in-depth knowledge of local and international tax systems, as well as our commitment to using modern, efficient tools like Exact Online. We provide personalized financial advice tailored to the unique needs of startups, small, and medium-sized businesses, ensuring you stay compliant while optimizing for growth.{"\n"}
				</Text>
			</Box>
			<Box
				display="grid"
				width="50%"
				lg-justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
				text-align="center"
				align-items="center"
				justify-items="center"
				max-height="450PX"
				background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/knowledge.webp?v=2024-09-17T12:21:47.264Z) center/cover no-repeat scroll padding-box"
				border-radius="32PX"
				border-width="1px"
				border-color="--color-light"
				border-style="solid"
			/>
		</Section>
		<Section background="#fafaf9">
			<Text as="h2" font="--lead" color="--secondary" margin="0px 0px 8px 0px">
				NEXT STEPS TO GET STARTED
			</Text>
			<Box min-width="100px" min-height="100px" margin="40px 0px 0px 0px">
				<Text margin="0px 0px 0px 0px" color="--primary" font="--lead" text-align="center">
					Getting started is simple. Contact us for a consultation where we’ll assess your current financial situation and tailor a package of services based on your needs. Our goal is to make your financial processes as smooth as possible.
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				text-align="center"
				align-items="center"
				display="grid"
				justify-items="center"
			>
				<Button
					href="#form"
					background="--color-utOrange"
					padding="8px 24px 8px 30p"
					color="--light"
					font="--lead"
					hover-transform="scale(1.1)"
					active-background="--color-secondary"
					transition="all 0.5s ease 0s"
					focus-box-shadow="unset"
					focus-background="--color-secondary"
					border-radius="5px"
					box-shadow="--l"
					type="link"
					text-decoration-line="initial"
				>
					CONTACT US
				</Button>
			</Box>
		</Section>
		<Section background="#fafaf9" display="none">
			<Button
				background="url(https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/Flag_of_Japan.svg?v=2024-06-08T13:56:50.908Z) 0% 0%/contain no-repeat"
				font="--base"
				top="596px"
				bottom="4999px"
				color="--secondary"
				width="max-content"
				padding="8px 24px 8px 73px"
				box-shadow="--xxl"
				border-radius="15px"
				hover-box-shadow="none"
				transition="all 0.5s ease 0s"
				opacity="0.8"
				hover-opacity="1"
				type="link"
				text-decoration-line="initial"
				href="/freelancers-jp"
			>
				日本語サイトはこちら
			</Button>
		</Section>
		<Components.Footer>
			<Override slot="Cookie Consent" />
			<Override slot="Creator" align-self="center" />
		</Components.Footer>
		<Button
			type="link"
			text-decoration-line="initial"
			position="fixed"
			right={0}
			bottom={0}
			margin="0px 5px 5px 0px"
			background="url(https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/WhatsAppButtonGreenMedium.svg?v=2024-06-08T12:53:12.802Z) 0% 0%/contain no-repeat"
			width="192px"
			height="42px"
			box-shadow="--xl"
			href="https://wa.me/31616555767?text=Hi%20Bos%20Balance%2C%20I%27m%20interested%20in%20your%20accounting%20services!"
			opacity="0.9"
			transition="all 0.5s ease 0s"
			hover-transform="rotate(3deg)"
		/>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bf71cb867c86001f1b6703"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});